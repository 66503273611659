<template>
  <div class="wp" v-loading.fullscreen="Loading">
    <!-- 登录页面右边 -->
    <div class="loginBox">
      <!-- <div class="text-crm">CRM销售系统</div> -->
      <!-- 文字 -- 登录 -->
      <span class="text-login">登录</span>
      <!-- <div class="text-login">
        
        </div> -->
      <!-- 账号密码input框 -->
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="loginForm"
        class="demo-ruleForm"
      >
        <!-- 账号输入框 -->
        <el-form-item class="login-name" prop="account">
          <el-input
          width="350px"
            type="search"
            v-model="ruleForm.account"
            placeholder="请输入账号/用户名"
          ></el-input>
        </el-form-item>

        <!-- 密码输入框 -->
        <el-form-item class="login-key" prop="password">
          <el-input
            v-model="ruleForm.password"
            type="password"
            placeholder="请输入密码"
            show-password
            @keyup.enter.native="submitForm('ruleForm')">
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            class="btnLogin"
            type="primary"
            @click="submitForm('ruleForm')"
            >立即登录</el-button
          >
        </el-form-item>
      </el-form>

    </div>
  </div>
</template>

<script>
import { getToken } from "@/utils/auth";
import {GetUserInfoByToken} from '../api/login'
import { mapActions, mapState } from "vuex";
import BackgroundImg from '@/assets/images/logBack.png'
export default {
    data() {
    return {
      ruleForm: {
        Background: BackgroundImg,
        account: "",
        password: "",
        type: 3,
      },
      rules: {
        account: [
          { required: true, message: "请填写账号/用户名", trigger: "blur" },
          //{ min: 3, max: 10, message: "长度在 3 到 6 个字符", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          //{ min: 3, max: 6, message: "长度在 3 到 6 个字符", trigger: "blur" },
        ],
      },
      Loading: false,
    };
  },

  computed: {
    ...mapState("login", ["msg"]),
  },

  async created() {
    console.log(window.history.pushState);
    let params = this.getQueryVariable('loginToken')
    console.log(params,'1111');
    if (params) {
      console.log("token登录")
      await this.loginActByToken({token:params});


    //  this.gettoken(params);

      //  window.history.pushState({},decodeURI(location.search),"/dashboard")
      if (getToken()) {
        this.$router.push({
          path: "/index",
        });
        // window.location.href = "https://new.likewon.cn/#/dashboard";
        // this.$message({
        //   message: "登录成功",
        //   type: "success",
        // });
      } else {
        this.$message.error(this.msg);
      }
    }
  },

  
  methods: {

    ...mapActions("login", ["loginAct","loginActByToken"]),
    submitForm() {
      this.$refs.loginForm.validate(async (valid) => {
        if (valid) {
          this.Loading = true;
          this.ruleForm.type = 3;
          let data={
            account:this.ruleForm.account,
            pwd:this.ruleForm.password
          }
          await this.loginAct(data);
          /*          console.log(getToken());*/
          // console.log(getToken())
          if (getToken()) {
            this.$router.push({ path: "/index" });
            this.$message({
              message: "登录成功",
              type: "success",
            });
          } else {
            this.$message.error(this.msg);
          }
          this.Loading = false;
        } else {
          this.Loading = false;
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.loginForm.resetFields();
    },
    // 轮播图事件
    change(oldIndex, newIndex) {
      this.num = oldIndex;
    },
    //跳转到找回密码页
    jumpPass(){
      //直接跳转
     this.$router.push("/password" );
    },
    getQueryVariable(variable) {
      var query = window.location.search.substring(1)
      var vars = query.split('&')
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=')
        if (pair[0] == variable) {
          return pair[1]
        }
      }
      return 0
    },
  },
};
</script>

<style scoped>
html,
body,
.wp {
  height: 100%;
  background-size: 100% 100%;
  background-image:url('../assets/images/logBack.png');
  /* background-attachment: fixed;
  display: flex; */
}

body {
  margin: 0px;
  padding: 0px;
}



.left {
  position: relative;
  width: 650px;
  height: 100%;
  background-color: #0390f4;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.el-carousel,
.el-carousel--horizontal {
  width: 580px;
  height: 310px;
  margin-top: 188px;
  margin-left: 33px;
}

/deep/.el-carousel__container {
  height: 310px !important;
}

.el-carousel__item:nth-child(2n + 1),
.el-carousel__item:nth-child(2n) {
  background-color: transparent;
}

.el-carousel__indicators--horizontal {
  position: absolute;
  /* bottom: 50px; */
}

.rightNow-call {
  margin-top: 100px;
  margin-left: 251px;
  font-size: 36px;
  font-weight: bold;
  color: #ffffff;
}

.signing {
  font-size: 24px;
  color: #ffffff;
  margin-left: 195px;
  margin-top: 10px;
}

.el-carousel {
  position: unset;
}


.text-login {
  font-size: 28px;
  font-weight: bolder;
  color: #333333;
  width: 350px;
  left: 300px;
  position: relative;
}

.demo-ruleForm {
  margin-left: 300px;
  width: 350px;
  height: 40px;
  background: #ffffff;
  border-radius: 5px;
}

.el-form-item__content {
  margin-left: 0px !important;
}

.login-name {
  margin-top: 60px;
}

.login-key {
  margin-top: 35px;
}

.el-button--primary {
  width: 450px;
  height: 40px;
  background: #0390f4;
  border-radius: 5px;
}

.el-message .el-message--success {
  height: 20px !important;
}
.btnLogin {
  width: 350px;
}

.loginBox{
   width: 350px;
    position: relative;
    left: 40%;
    top: 150px;
}
/deep/ .el-input {
  width: 350px;
}
</style>
